import useSWR from "swr";

export function useTiers() {
  const { data, error, isLoading } = useSWR(`/tiers`);

  return {
    tiers: data,
    isLoading,
    error,
  };
}
