import { Card } from "@mui/material";
import { useParams } from "@reach/router";
import { useProject } from "../../hooks/useProject";
import { useTeam } from "../../hooks/useTeam";
import { useDesign } from "../../hooks/useDesign";
import { sumNumbers } from "./Projects";
import Quote from "./Quote";
import { getSections } from "../../util/fence";

export const ProjectNumbers = () => {
  const { projectId } = useParams();
  const { teamIsLoading, team, error: teamError } = useTeam();
  const {
    project,
    isLoading: projectIsLoading,
    error: projectError,
  } = useProject(projectId);
  const { design, isLoading, error } = useDesign(project.designId);

  if (isLoading || teamIsLoading || projectIsLoading) {
    return <div className="spinner" />;
  }

  if (error?.response?.data === "Design is not in team") {
    return null;
  }

  if (error || teamError || projectError) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  if (!team.permissions.includes("seeQuote")) {
    return null;
  }

  if (!project.lengths && team.permissions.includes("editProject")) {
    return <p>Please enter the length of the fence</p>;
  } else if (!project.lengths && !team.permissions.includes("editProject")) {
    return <p>Have an owner or a quoter add the length to the fence</p>;
  }
  if (
    (!team.team.labourCostPerFt ||
      !team.team.stateTax ||
      !team.team.materialMultiplier) &&
    team.tierLimits.seePrices &&
    !team.permissions.includes("editTeam")
  ) {
    return <p>Have an owner enter values in Team Page</p>;
  }

  if (
    (!team.team.labourCostPerFt ||
      !team.team.stateTax ||
      !team.team.materialMultiplier) &&
    team.tierLimits.seePrices &&
    team.permissions.includes("editTeam")
  ) {
    return <p>Enter values in Team Page</p>;
  }

  if (!team.tierLimits.seePrices) {
    return (
      <Card>
        <div>
          <p>Please Subscribe to a Higher Tier to see prices</p>
        </div>
      </Card>
    );
  }

  let numberOfPanelsPerLengthCalc = Object.values(project.lengths).map((s) =>
    getSections(parseFloat(s), parseFloat(design.lengthOfSection || 1))
  );

  const totalSections = numberOfPanelsPerLengthCalc.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  let totalPosts = numberOfPanelsPerLengthCalc.reduce(
    (partialSum, a) => partialSum + a + 1,
    0
  );

  totalPosts = totalPosts - (project?.sharedPosts || 0);

  const totalCalc = () => {
    let total = 0;
    design.materials.forEach((m) => {
      if (m.fenceCategory === "post") {
        total += m.price * totalPosts * m.quantity;
      } else {
        total += m.price * totalSections * m.quantity;
      }
    });
    return total;
  };

  const total = totalCalc();
  const plusTax = (total * parseFloat(team.team.stateTax / 100 + 1)).toFixed(2);

  const adjustCost = parseFloat(project.adjustCost) || 0;

  console.log(adjustCost);
  const materialMultiplier = (
    plusTax * parseFloat(team.team.materialMultiplier / 100 + 1)
  ).toFixed(2);

  const totalLabour = (
    team?.team?.labourCostPerFt * sumNumbers(Object.values(project.lengths))
  ).toFixed(2);

  const totalCost = (
    parseFloat(totalLabour) + parseFloat(materialMultiplier)
  ).toFixed(2);

  const totalClientCost = (
    totalCost * parseFloat(team.team.stateTax / 100 + 1)
  ).toFixed(2);

  const pricePerFtBeforeTax = (
    adjustCost +
    totalCost / sumNumbers(Object.values(project.lengths))
  ).toFixed(2);

  const costAdjustment = (
    adjustCost * sumNumbers(Object.values(project.lengths))
  ).toFixed(2);

  const totalClientCostPerFt = (
    totalClientCost / sumNumbers(Object.values(project.lengths))
  ).toFixed(2);

  return (
    <Card>
      <div>
        <h1>Quote:</h1>
        {team.permissions.includes("seeNumbers") ? (
          <>
            <p>Plus Material Multiplier: ${materialMultiplier}</p>
            <p>Labour Cost: ${totalLabour}</p>
            <p>Total Cost: ${totalCost}</p>{" "}
            <p>
              Business Profit: ${(totalCost - totalLabour - plusTax).toFixed(2)}
            </p>
            <p>Cost Adjustment: ${costAdjustment}</p>
          </>
        ) : null}
        <p>
          <b>Customer Cost including Tax: ${totalClientCost}</b>
        </p>
        <p>${pricePerFtBeforeTax} plus tax per ft</p>
        <p>${totalClientCostPerFt} per ft </p>
      </div>

      <Quote totalCost={totalCost} pricePerFtBeforeTax={pricePerFtBeforeTax} />
    </Card>
  );
};
