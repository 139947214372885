import { Formik, Form, Field } from "formik";
import { TextField, RadioGroup } from "formik-mui";
import { api } from "../util/api";
import { Button, Grid, Card, FormControlLabel, Radio } from "@mui/material";
import * as Yup from "yup";
import ButtonAppBar from "../comp/NavBar";
import { useSnackbar } from "notistack";
import { useTiers } from "../hooks/useTier";

const NewCompany = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { tiers, isLoading, error } = useTiers();

  const newCompany = async (values) => {
    // enqueueSnackbar("New Company Created");
    const subscribe = await api("POST", "/team", values);

    console.log(subscribe);
    window.location.href = subscribe.url;
    // console.log(newTeam);
  };

  if (isLoading) {
    return <div className="spinner" />;
  }

  if (error) {
    return (
      <p>
        Error! please refresh the page, if the problem persists contact
        fencewizapp@gmail.com
      </p>
    );
  }

  return (
    <>
      <ButtonAppBar />
      <Card>
        <div>
          <h1>Welcome to Fencewiz.com</h1>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Card>
                <Grid container>
                  <Grid item xs={6}>
                    <div>
                      <h2>General Contractor</h2>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h2>${tiers.contractor.cost}</h2>
                        <div>
                          <p>
                            <i> Per Month</i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <p>1 User</p>
                    <p>1 Design</p>
                    <p>Owner Role</p>
                    <p>Get Total Material Quantities</p>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card>
                <Grid container>
                  <Grid item xs={6}>
                    <div>
                      <h2>Small Business</h2>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h2>${tiers.small.cost}</h2>

                        <div>
                          <p>
                            <i> Per Month</i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <p>3 Users</p>
                    <p>3 Designs</p>
                    <p>Owner Role</p>
                    <p>Foreman Role</p>
                    <p>Quoter Role</p>
                    <p>Set Material Prices and Markup </p>
                    <p>Set State Tax </p>
                    <p>Set Labour per ft</p>
                    <p>Customer Cost per ft</p>
                    <p>Get Total Material Cost and Quantities </p>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card>
                <Grid container>
                  <Grid item xs={6}>
                    <div>
                      <h2>Enterprise</h2>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h2>${tiers.enterprise.cost}</h2>
                        <div>
                          <p>
                            <i> Per Month</i>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <p>10 Users</p>
                    <p>100 Designs</p>
                    <p>Owner Role</p>
                    <p>Foreman Role</p>
                    <p>Quoter Role</p>
                    <p>Installer Role</p>
                    <p>Set Material Prices and Markup </p>
                    <p>Set State Tax </p>
                    <p>Set Labour per ft</p>
                    <p>Customer Cost per ft</p>
                    <p>Get Total Material Cost and Quantities </p>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <h2></h2>

          <Card>
            <div>
              <h3>Create New Company</h3>
              <Formik
                initialValues={{
                  companyName: "",
                  website: "",
                  tier: "",
                }}
                validationSchema={Yup.object().shape({
                  companyName: Yup.string()
                    .min(2, "Too Short!")
                    .max(50, "Too Long!")
                    .required("Required"),
                  website: Yup.string()
                    .min(2, "Too Short!")
                    .max(50, "Too Long!")
                    .required("Required"),
                  tier: Yup.string().required("Required"),
                })}
                onSubmit={newCompany}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          name="companyName"
                          // type="text"
                          label="Company Name"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          name="website"
                          // type="text"
                          label="Website"
                        />
                      </Grid>
                    </Grid>

                    <h3>Tier:</h3>

                    <Grid item xs={12} md={6}>
                      <Field component={RadioGroup} name="tier">
                        <FormControlLabel
                          value="contractor"
                          control={<Radio disabled={isSubmitting} />}
                          label="One Time Contractor"
                        />
                        <FormControlLabel
                          value="small"
                          control={<Radio disabled={isSubmitting} />}
                          label="New/Small Business"
                          disabled={isSubmitting}
                        />
                        <FormControlLabel
                          value="enterprise"
                          control={<Radio disabled={isSubmitting} />}
                          label="Enterprise Business"
                          disabled={isSubmitting}
                        />
                      </Field>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {isSubmitting ? (
                        <div className="spinner" />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          Subscribe
                        </Button>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
          {/* <Grid item xs={12} md={6}>
            <Card>
              <div>
                <h2>Join Existing Company</h2>
                <Formik
                  initialValues={{
                    code: "",
                  }}
                  validationSchema={Yup.object().shape({
                    code: Yup.string()
                      .min(2, "Too Short!")
                      .max(50, "Too Long!")
                      .required("Required"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      setSubmitting(false);
                      alert(JSON.stringify(values, null, 2));
                    }, 500);
                  }}
                >
                  {({ submitForm, isSubmitting }) => (
                    <Form>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={TextField}
                          name="code"
                          // type="text"
                          label="Code"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {isSubmitting ? (
                          <div className="spinner" />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            Join
                          </Button>
                        )}
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </Grid> */}
        </div>
      </Card>
    </>
  );
};

export default NewCompany;
